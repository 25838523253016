import React from "react";
import PropTypes from "prop-types";
import classnames from 'classnames';
import parse from 'html-react-parser';

const NewsfeedCard = ({ tab, card_data }) => (
    <div className="card card-shadow card-hov">
        {card_data.featured ? <span className="tag featured-tag is-warning">Featured</span> : null}
            <a href={card_data.link} rel="noopener noreferrer" target="_blank">
            {
                card_data.image ?     
                <div className="card-image">
                    <figure className="image">
                    <img src={card_data.image} className="radius-top-10" alt="Placeholder"/>
                    </figure>
                </div> : null
            }
        
        <div className="card-content pd-top-15">
            <div className="media mg-bottom-20">
                {tab === 'mentions' ?
                    <div className="media-left margin-media">
                        <figure className="image is-24x24">
                            <img src={ card_data.avatar } className="is-rounded" alt="Placeholder"/>
                        </figure>
                    </div>
                    : 
                    null
                }
                <div className="media-content">
                    <p className="title is-7 has-text-grey has-text-weight-normal pd-top-5 no-overflow">{tab === 'mentions' ? card_data.author : <span className="is-pulled-left is-size-7">{card_data.date}</span>} </p>
                </div>
            </div>
            <div className={classnames('content font-15 has-text-left has-text-grey-dark', {'' : tab === 'articles'})}>
                {parse(card_data.text)}
            </div>
            {tab === 'articles' ? 
                <div className="media">
                    <div className="media-left margin-media">
                        <figure className="image is-24x24">
                            <img src={card_data.sub_image} className="is-rounded" alt="Placeholder"/>
                        </figure>
                    </div>
                    <div className="media-content">
                        <p className="title is-7 has-text-grey has-text-weight-normal pd-top-5 no-overflow">{card_data.title}</p>
                    </div>
                </div> : 
                <div className="media">
                    <div className="media-content">
                        <p className="title is-7 has-text-grey has-text-weight-normal pd-top-5 no-overflow">
                            <span className="is-pulled-right is-size-7 social-logo"><img src={card_data.platform} alt={card_data.platform}/></span>
                        </p>
                    </div>
                </div>
            }
        </div>
        </a>
    </div>
)

NewsfeedCard.propTypes = {
    card_data: PropTypes.object,
}

export default NewsfeedCard