import React, { Component } from 'react';
import Newsfeed from './Newsfeed';
import classnames from 'classnames';
import newsroom_img from '../../images/newsroom.png';
import Awards from './Awards';
import NewsroomVideos from './NewsroomVideos';

export default class Newsroom extends Component {

    state = {
        selected_tab: 0
    }

    changetab = (selected_tab) => {
        this.setState({selected_tab});
    }

    render() {
        const { selected_tab } = this.state;
        return (
            <div className={selected_tab ? ' has-text-centered social-mentions': 'has-text-centered article-mentions'}>
                <h1 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold pd-top-75">
                    <span>Newsroom</span>
                    <img src={newsroom_img} width="50px" alt="logo" className="middle mg-10"/>
                </h1>
                <h2 className="is-size-5-tablet is-size-6-mobile pd-10"><b>As Amber travels the world, catch all the action here</b><br/> Be a part of our ever-growing community</h2>
                <div className="columns mg-top-20 flex-wrap bg-path-4 no-mg-right-left">
                    <div className="tabs is-centered column is-10 mar-auto">
                        <ul>
                            <li onClick={this.changetab.bind(this, 0)} className={classnames('', {'is-active': selected_tab === 0})}><button>Article Mentions</button></li>
                            <li onClick={this.changetab.bind(this, 1)} className={classnames('', {'is-active': selected_tab === 1})}><button>Social Media Mentions</button></li>
                        </ul>
                    </div>
                <div className="column is-11 mar-auto">
                    <Newsfeed tab={selected_tab ? 'mentions' : 'articles'} />
                </div>
                </div>
                <div className="columns mg-top-20 flex-wrap no-mg-right-left" id="NewsroomAwards">
                    <Awards />
                </div>
                <div className="columns mg-top-20 bg-path-4 flex-wrap no-mg-right-left" id="FounderVideos">
                    <NewsroomVideos />
                </div>
            </div>
        )
    }
}
