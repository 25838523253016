import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NewsRoomHead from "../components/newsroom/Newsroom";
import NewsRoomPR from "../components/newsroom/NewsfeedPR";

const Newsroom = () => (
  <Layout>
    <SEO title="Amber by inFeedo | Newsroom" description="Amber is an AI chatbot that talks to your employees to helps you measure employee experience in your organization. As Amber travels the world, catch all the action here. Be a part of our ever-growing community."/>
    <NewsRoomHead/>
    <NewsRoomPR/>
  </Layout>
)

export default Newsroom;