import React, { Component } from 'react'
import nasscom from '../../images/nasscom.png'
import the_vault from '../../images/the-vault.png'
import thinking_image from '../../images/thinking.png'
import tech_hr from '../../images/tech-hr.png'
import owler from '../../images/owler.png'
import AwardItem from './AwardItem';

export class Awards extends Component {
    state = {
        awards: [
            {
                image: nasscom,
                text:'Recognised among <b>Top 10 Startups in<br/> Nasscom</b> DeepTech Club',
                link:'',
                extra_class: 'mg-bottom-30'
            },
            {
                image: the_vault,
                text:'<b>Funded on the spot</b> in India’s Shark <br/> Tank (The Vault Show)',
                link:'',
                extra_class: 'mg-bottom-30'
            },
            {
                image: thinking_image,
                text:'<b>Won the Number 1 A.I. Startup Award</b> <br/> at iTech 2017 by ITC Info Tech',
                link:'',
                extra_class: 'mg-bottom-30'
            },
            {
                image: tech_hr,
                text:'Recognised among <b>Top 30 <br/> Startups</b> at TechHR 2017',
                link:'',
                extra_class: 'is-offset-2'
            },
            {
                image: owler,
                text:'Awarded The Most Popular/Top Trending <br/>Startup in Gurgaon by <b>Owler</b>',
                link:'',
                extra_class: ''
            },
        ]
    }

    render() {
        const { awards } = this.state;
        return (
            <div className="mar-top-awards is-11 column mar-auto">
                <h2 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold">Awards and Recognitions</h2>
                <div className="awards mar-top-160 columns flex-wrap">
                    {awards.map((award, index) => (
                        <span className={`column is-4 ${award.extra_class}`} key={index}><AwardItem award={award} /></span>
                    ))}
                </div>
            </div>
        )
    }
}

export default Awards
