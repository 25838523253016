import React from 'react'
import parse from 'html-react-parser';

function AwardItem({award}) {
    return (
        <div className="award">
            <img alt="award" src={award.image} />
            <p className="mg-top-15 font-15">{parse(award.text)}</p>
        </div>
    )
}

export default AwardItem
