import React, { Component } from 'react'
import NewsroomVideo from './NewsroomVideo'
import nasscom from '../../images/nasscom-video.png'
import vault from '../../images/vault-video.png'
import tedx from '../../images/tedx-video.png'
import nassom_video_logo from '../../images/nasscom-video-logo.png'
import vault_video_logo from '../../images/the-vault.png'
import ted_video_logo from '../../images/ted-video-logo.png'
import VideoModal from '../reuseable/VideoModal';
import tanmaya_nr_video from '../../images/TANMAYA_NR_VIDEO.png'
import infeedo_logo from '../../images/infeedo.svg'
import tanmaya_bbc from '../../images/FoundervideoBBC.png'
import bbc_logo from '../../images/BBClogo.png'
import tanmaya_cnbc from '../../images/FounderVideoCNBC.png'
import cnbc_logo from '../../images/CNBClogo.png'


export class Videos extends Component {
    
    state = {
        videos : [
            {
                thumbnail: tanmaya_bbc,
                id: 'uSyqZp_7POM',
                text: "BBC on The Digital Friend Helping Staff with Stress at Work",
                logo: bbc_logo
            },
            {
                thumbnail: tanmaya_cnbc,
                id: 'iCCfGkh0wOM',
                text: "CNBC-TV18 interview with Tanmaya & Ritesh Malik",
                logo: cnbc_logo
            },
            {
                thumbnail: tanmaya_nr_video,
                id: 'OnZ_raMh7oo',
                text: "60s Pitch by inFeedo's Founder Tanmaya Jain",
                logo: infeedo_logo
            },
            {
                thumbnail: nasscom,
                id: 'y5jCtH76Gkc',
                text: 'Tanmaya Jain speaking at the <br> NASSCOM HR Summit 2017',
                logo: nassom_video_logo
            },
            {
                thumbnail: vault,
                id: 'FQZfLRrcomo',
                text: 'The Vault | Pitch - Infeedo - AI based HR Analytics Solution',
                logo: vault_video_logo
            },
            {
                thumbnail: tedx,
                id: '2N1QcGdJZtc',
                text: 'Is A.I more Human? | Tanmaya Jain TEDxJMI',
                logo: ted_video_logo
            }
        ],
        selected_video_id: '',
        is_active: false
    }

    selectVideo = (selected_video_id) => {
        this.setState({selected_video_id, is_active: true});
    }

    onClose = () => {
        this.setState({is_active: false});
    }

    render() {
        const { videos, selected_video_id, is_active } = this.state;
        return (
            <div className="mar-top-100 column mar-auto is-11 pd-bottom-40 pd-15-mobile">
                <h2 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold has-text-dark">Founder Videos</h2>
                <div className="awards mg-top-30 columns flex-wrap pd-15-mobile">
                    {videos.map((video, index) => (
                       <span className="is-4 column video" key={index}><NewsroomVideo video={video} onVideoClick={this.selectVideo}/></span>
                    ))}
                </div>
                {is_active ? <VideoModal video_id={selected_video_id} onClose={this.onClose} is_active={is_active}/> : null}
            </div>
        )
    }
}

export default Videos