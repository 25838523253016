import React, { Component } from 'react';
import NewsfeedCard from './NewsfeedCard';
import kalzoom from '../../images/kalzoom.png'
import infeedo_team from '../../images/infeedo.png'
import infeedo_team2 from '../../images/infeedo-team.png'
import infeedo_team3 from '../../images/infeedo-team-old.png'
import infeedo_team4 from '../../images/infeedo-team-init.jpg'
import zawya_logo from '../../images/zawya.png'
import sharaf_dg from '../../images/sharaf.jpg'
import et_logo from '../../images/et.jpg'
import pr_news_logo from '../../images/prnews.png'
import oyo_logo from '../../images/oyo.jpeg'
import mint_logo from '../../images/mint.jpg'
import ent from '../../images/ent.jpg'
import tanmaya from '../../images/tanmaya.png'
import tanmaya_2 from '../../images/tanmaya-2.jpg'
import peoplematters from '../../images/peoplematters.jpg'
import peoplematters_logo from '../../images/pm.png'
import ai_hr from '../../images/employee.png'
import amber_hi from '../../images/amber-hi.png'
import yourstory from '../../images/yourstory.png'
import inc42 from '../../images/inc42.png'
import booth from '../../images/booth.jpg'
import aim from '../../images/aim.webp'
import aim_infeedo from '../../images/aim-infeedo.jpg'
import chatbot from '../../images/chatbot.png'
import techseen from '../../images/techseen.jpg'
import amber_know from '../../images/amber-know.jpg'
import predictive from '../../images/predictive.png'
import twitter from '../../images/twitter-mentions.png'
import linkedin from '../../images/linkedin-mentions.png'
import rohit_suri from '../../images/rohit-suri.png'
import image_rohit from '../../images/image-rohit.png'
import image_sonia from '../../images/image-sonia.png'
import mediacom from '../../images/mediacom.png'
import image_altimetrik from '../../images/image-altimetrik.png'
import altimetrik from '../../images/altimetrik.png'
import image_cavin from '../../images/image-cavin.png'
import cavinkare from '../../images/cavinkare.png'
import image_emaar from '../../images/image-emaar.png'
import emaar from '../../images/emaar-hospitality.png'
import image_emaar_aseem from '../../images/image-emaar-aseem.png'
import aseem from '../../images/aseem.png'
import image_marico from '../../images/image-marico.png'
import ashutosh from '../../images/ashutosh.png'
import image_crompton from '../../images/image-crompton.png'
import satyajit from '../../images/satyajit.png'
import image_bharti from '../../images/image-bharti.png'
import mmh_beg from '../../images/mmh-beg.png'
import et_rise from '../../images/et-rise.png'
import image_ifcci from '../../images/image-iffci.png'
import indo_french from '../../images/indo-french.png'
import authority_magazine from '../../images/authority-magazine.png'
import tanmaya_jain from '../../images/tanmaya-jain.png';
import employee_bw from '../../images/employee-bw.jpg';
import robo from '../../images/robo.jpg';
import bw from '../../images/bw.png'
import entrepreneur_article from '../../images/entrepreneur-article.png'
import syukri_sudari from '../../images/syukri-sudari.jpg'
import human_resources from '../../images/human-resources.ico'
import david from '../../images/david-green.jpg'
import david_post from '../../images/david-post.jpeg'
import manuel_vidal from '../../images/manuel-vidal.jpg'
import image_axa from '../../images/image-axa.jpeg'
import everest_image from '../../images/everest-image.jpg'
import everest_thumb from '../../images/everest-thumb.png'
import max from '../../images/max.jpeg'
import user from '../../images/user.png'
import mindful from '../../images/mindful.jpg'
import fox34 from '../../images/fox34.jpg'
import fox_icon from '../../images/fox-icon.png'
import times_tanmaya from '../../images/times-tanmaya.jpg'
import timesjobs from '../../images/timesjobs.png'
import sun_life from '../../images/sun-life.jpg'
import thriveglobal from '../../images/thriveglobal.png'
import ai_bi from '../../images/business-insiders.jpeg'
import etr from '../../images/et-rise.jpeg'
import ai_bw from '../../images/ai-bw.jpeg'
import ai_bs from '../../images/business-std.png'
import ai_mc from '../../images/money-control.png'
import mc_logo from '../../images/mc-logo.png'
import vjamritra from '../../images/vjamritraj.png'
import vjamritra_logo from '../../images/vjamritraj-logo.png'
import et_tech_logo from '../../images/et-tech-logo.png'
import et_tech_img from '../../images/et-tech-img.png'
import vccircle_logo from '../../images/vc-circle-logo.png'
import kr_asia_logo from '../../images/kr-asia-logo.png'
import kr_asia_img from '../../images/kr-asia-img.jpg'
import people_matters_logo from '../../images/people-matters-logo.png'
import people_matters_img from '../../images/people-matters-img.jpg'
import business_line_logo from '../../images/business-line-logo.png'
import your_story_logo from '../../images/your-story-logo.png' 
import cnbc_logo from '../../images/cnbc-logo.png'
import cnbc_img from '../../images/cnbc-img.png'
import inc42_logo from '../../images/inc42-logo.png' 
import inc42_img from '../../images/inc42-img.jpg'
import inshorts_logo from '../../images/inshorts-logo.png'
import inshorts_img from '../../images/inshorts-img.png'
import livemint_logo from '../../images/livemint-logo.png'
import livemint_img from '../../images/livemint-img.png' 
import linkedin_logo from '../../images/linkedin-logo.png'
import linkedin_img from '../../images/linkedin-img.png'
import techstory_logo from '../../images/techstory-logo.jpg'
import techstory_img from '../../images/techstory-img.jpeg'
import tarkesh_gupta from '../../images/tarkesh-gupta.jpeg'
import tarkesh_gupta_sm from '../../images/tarkesh-gupta-sm.jpeg'
import safinas from '../../images/safinas-img.jpeg'
import safinas_sm from '../../images/safinas-sm.jpeg'
import taiwo_img from '../../images/taiwo-img.jpeg'
import taiwo_sm from '../../images/taiwo-sm.png'
import syukri_sm from '../../images/syukri-sm.jpg'
import peter from '../../images/peter-img.jpeg'
import startup_news_sm from '../../images/startup-news.jpeg'
import startup_wonders_sm from '../../images/startup-wonders.jpeg'
import business_ex_sm from '../../images/business-ex.jpeg'
import hey_pitch_sm from '../../images/hey-pitch.jpeg'
import antrapreneur_business_sm from '../../images/antrapreneur-business.jpeg'  
import kaizen_young_sm from '../../images/kaizen-young.jpeg'
import volanews_sm from '../../images/vola-news.jpeg'
import pm_article from '../../images/pm-article.jpg'
import pm_logo from '../../images/pm-logo.png'
import infeedoglobalarticle_img from '../../images/infeedoblogarticlecoverset.jpg'
import infeedoglobal_mentalwell_img from '../../images/infeedoblogarticlecoversmentalwell.jpg'
import y_combinator_gear from '../../images/y-combinator-gears1.png'
import tech_crunch_logo from '../../images/tech-crunch-logo.png'
import crosstalk_logo from '../../images/crosstalk-logo.png'
import tanmaya_crosstalk from '../../images/tanmaya-crosstalk.jpeg'
import horizontal_saas from '../../images/creative-horizontal-saas.jpg'
import people_matter_funding from '../../images/people-matters-funding.jpg'
import bbc_logo from '../../images/bbc-logo.svg'
import bbc_img from '../../images/bbc-articles.png'
import new_journalism_logo from '../../images/new-journalism-logo.png'
import getaka_logo from '../../images/getaka-logo.png'
import amber_chatbot_scaled from '../../images/amber-chatbot-scaled.jpeg'
import yahoo_news_logo from '../../images/yahoo-news-logo.png'
import tech_in_asia_logo from '../../images/tech-in-asia-logo.png'
import mc_diwali_logo from '../../images/mc-diwali.png'
import business_line_full_logo from '../../images/business-line-full-logo.png'
import business_line_img from '../../images/business-line-3.2.jpeg'
import et_cio_logo from '../../images/et-cio-logo.png'
import et_cio_img from '../../images/et-cio-img.png'
import start_lab_img from '../../images/inFeedo-funding.png'
import ritesh_malik from '../../images/ritesh-malik.jpeg'
import maninder_gulati from '../../images/maninder-gulati.jpeg'
import kanishka_mallick from '../../images/kanishka-mallick.jpeg'
import affin_bank_talk from '../../images/affin-bank-talk.jpeg'
import tanmaya_cnbc from '../../images/tanmaya-cnbc.png'
import your_story_23dec from '../../images/your-story-23dec.png'

export default class Newsfeed extends Component {

    state = {
        articles: [
            {
                image: your_story_23dec,
                sub_image: your_story_logo,
                date: '23 Dec, 2020',
                title: 'Your Story',
                text: "inFeedo features in Stellaris Venture Partners and IFC’s AI4Biz's first cohort of 10 startups",
                link: 'https://yourstory.com/2020/12/stellaris-venture-partners-ifc-ai4biz-ai-startups-saas',
                featured: false
            },
            {
                image: tanmaya_cnbc,
                sub_image: cnbc_logo,
                date: '26 Nov, 2020',
                title: 'CNBC',
                text: "Tanmaya speaks to CNBC after inFeedo raises $3.2M from YC & others",
                link: 'https://youtu.be/DM2CyUxZ_UE',
                featured: false
            },
            {
                image: affin_bank_talk,
                sub_image: linkedin_logo,
                date: '26 Nov, 2020',
                title: 'LinkedIn',
                text: "Syukri Sudari, CPO, Affin Bank Group talks about Amber",
                link: 'https://www.linkedin.com/posts/syukri-sudari_the-5-biggest-mistakes-companies-make-with-activity-6734819635021139968-YOAK',
                featured: false
            },
            {
                image: tanmaya_2,
                sub_image: your_story_logo,
                date: '16 Nov, 2020',
                title: 'YourStory',
                text: "[Funding alert] SaaS startup inFeedo raises $3.2M led by Bling Capital",
                link: 'https://yourstory.com/2020/11/funding-saas-startup-infeedo-bling-capital',
                featured: true
            },
            {
                image: tanmaya_2,
                sub_image: inc42_logo,
                date: '16 Nov, 2020',
                title: 'inc42',
                text: "Employee Engagement Platform InFeedo Bags $3.2 Mn For Asia-Pacific Expansion",
                link: 'https://www.inc42.com/buzz/employee-engagement-platform-infeedo-bags-3-2-mn-for-asia-pacific-expansion/',
                featured: true
            },
            {
                image: business_line_img,
                sub_image: business_line_full_logo,
                date: '16 Nov, 2020',
                title: 'The Hindu Business Line',
                text: "inFeedo raises $3.2 million from Bling Capital",
                link: 'https://www.thehindubusinessline.com/companies/infeedo-raises-32-million-from-bling-capital/article33090826.ece',
                featured: false
            },
            {
                image: tanmaya_2,
                sub_image: vccircle_logo,
                date: '16 Nov, 2020',
                title: 'VC Circle',
                text: "Bling Capital, Freshworks founder bet on SaaS startup inFeedo",
                link: 'https://www.vccircle.com/bling-capital-freshworks-founder-bet-on-saas-startup-infeedo/',
                featured: false
            },
            {
                image: inshorts_img,
                sub_image: mc_diwali_logo,
                date: '16 Nov, 2020',
                title: 'Money Control',
                text: "inFeedo raises $3.2 million from Bling, Freshworks founders",
                link: 'https://www.moneycontrol.com/news/business/startup/infeedo-raises-3-2-million-from-bling-freshworks-founders-6113781.html',
                featured: false
            },
            {
                image: inshorts_img,
                sub_image: tech_in_asia_logo,
                date: '16 Nov, 2020',
                title: 'Tech in Asia',
                text: "Y Combinator-backed inFeedo bags $3.2m in round led by ex-Google, Facebook exec",
                link: 'https://www.techinasia.com/combinatorbacked-infeedo-bags-32m-bling-capitals-funder',
                featured: false
            },
            {
                image: people_matter_funding,
                sub_image: people_matters_logo,
                date: '16 Nov, 2020',
                title: 'People Matters',
                text: "inFeedo raises US$3.2 mn in oversubscribed round",
                link: 'https://www.peoplematters.in/news/hr-technology/infeedo-raises-us32-mn-in-oversubscribed-round-27599',
                featured: false
            },
            {
                image: inc42_img,
                sub_image: tech_crunch_logo,
                date: '16 Nov, 2020',
                title: 'TechCrunch',
                text: "InFeedo raises $3.2 million led by Bling Capital ",
                link: 'https://techcrunch.com/2020/11/12/infeedo-raises-3-2m-led-by-bling-capital-delivers-1-1m-exit-to-early-employees-and-investors/',
                featured: false
            },
            {
                image: inc42_img,
                sub_image: yahoo_news_logo,
                date: '16 Nov, 2020',
                title: 'Yahoo News',
                text: "InFeedo raises $3.2 million led by Bling Capital",
                link: 'https://news.yahoo.com/infeedo-raises-3-2m-led-201020137.html',
                featured: false
            },
            {
                image: amber_chatbot_scaled,
                sub_image: getaka_logo,
                date: '16 Nov, 2020',
                title: 'Getaka',
                text: "InFeedo raises $3.2M led by Bling Capital, delivers $1.1M exit to early employees and investors",
                link: 'https://getaka.co.in/tech-news/infeedo-raises-3-2m-led-by-bling-capital-delivers-1-1m-exit-to-early-employees-and-investors/',
                featured: false
            },
            {
                image: inc42_img,
                sub_image: new_journalism_logo,
                date: '16 Nov, 2020',
                title: 'The New York Journalism',
                text: "InFeedo raises $3.2M led by Bling Capital, delivers $1.1M exit to early employees and investors",
                link: 'https://nyjournalism.com/2020/11/12/infeedo-raises-3-2m-led-by-bling-capital-delivers-1-1m-exit-to-early-employees-and-investors-nyjournalism/',
                featured: false
            },
            {
                image: et_cio_img,
                sub_image: et_cio_logo,
                date: '16 Nov, 2020',
                title: 'The Economic Times',
                text: "How AI is helping make employees happy at Genpact",
                link: 'https://cio.economictimes.indiatimes.com/news/next-gen-technologies/how-ai-is-helping-make-employees-happy-at-genpact/79183418',
                featured: false
            },
            {
                image: bbc_img,
                sub_image: bbc_logo,
                date: '30 Oct, 2020',
                title: 'BBC',
                text: "With India’s economy taking a hit, could measuring happiness help companies bounce back? ",
                link: 'https://www.bbc.com/worklife/article/20201009-why-happiness-at-work-is-big-business-in-india',
                featured: false
            },
            {
                image: tanmaya_crosstalk,
                sub_image: crosstalk_logo,
                date: '8 Oct, 2020',
                title: 'Crosstalk Conversations',
                text: "The one where 2020 was hell until YC - Tanmaya Jain, Founder and CEO inFeedo",
                link: 'https://linktr.ee/tanmayajain',
                featured: false
            },
            {
                image: horizontal_saas,
                sub_image: inc42_logo,
                date: '8 Oct, 2020',
                title: 'inc42',
                text: "The Dialogue | Horizontal SaaS And The New Digital Enterprise Opportunity",
                link: 'https://inc42.com/videos/the-dialogue-horizontal-saas-and-the-new-digital-enterprise-opportunity/',
                featured: false
            },
            {
                image: people_matter_funding,
                sub_image: people_matters_logo,
                date: '8 Oct, 2020',
                title: 'People Matters',
                text: "Funding & acquisitions in the HR tech and work space in 2020",
                link: 'https://www.peoplematters.in/article/hr-technology/funding-acquisitions-in-the-hr-tech-and-work-space-july-sep-2020-27133',
                featured: false
            },
            {
                image: y_combinator_gear,
                sub_image: tech_crunch_logo,
                date: '31 Aug, 2020',
                title: 'Tech Crunch',
                text: "Here are the 94 companies from Y Combinator’s Summer 2020 Demo Day 2",
                link: 'https://techcrunch.com/2020/08/25/here-are-the-94-companies-from-y-combinators-summer-2020-demo-day-2/',
                featured: false
            },
            {
                image: infeedoglobal_mentalwell_img,
                sub_image: et_logo,
                date: '31 Aug, 2020',
                title: 'The Economic Times',
                text: "Prioritising employees' mental wellbeing amidst turbulent times",
                link: 'https://hr.economictimes.indiatimes.com/news/trends/employee-experience/prioritising-employees-mental-wellbeing-amidst-turbulent-times/77753844',
                featured: false
            },
            {
                image: infeedoglobalarticle_img,
                sub_image: et_logo,
                date: '31 Aug, 2020',
                title: 'The Economic Times',
                text: "If HR heads don't engage with business leaders, then they just become operators: Puja Kapoor, OLX India - ETHRWorld",
                link: 'https://hr.economictimes.indiatimes.com/news/trends/leadership/if-hr-heads-dont-engage-with-business-leaders-then-they-just-become-operators-puja-kapoor-olx-india/77414771',
                featured: false
            },
            {
                image: pm_article,
                sub_image: pm_logo,
                date: '31 Aug, 2020',
                title: 'People Matters',
                text: 'Dream in digital, dare in reality: Piyush Mehta, CHRO, Genpact',
                link: 'https://www.peoplematters.in/amp-skilling-dream-in-digital-dare-in-reality-piyush-mehta-chro-genpact-26651',
                featured: false
            },
            {
                image: cnbc_img,
                sub_image: cnbc_logo,
                date: '5 Aug, 2020',
                title: 'CNBC TV 18',
                text: 'SaaS-based startup inFeedo raises $700K from Y Combinator and others',
                link: 'https://www.cnbctv18.com/technology/saas-based-startup-infeedo-raises-700k-from-y-combinator-and-others-6535811.htm',
                featured: false
            },
            {
                image: livemint_img,
                sub_image: livemint_logo,
                date: '5 Aug, 2020',
                title: 'Live Mint',
                text: 'inFeedo raises funding from Y Combinator, others',
                link: 'https://www.livemint.com/companies/start-ups/infeedo-raises-funding-from-y-combinator-others-11596528733818.html',
                featured: false
            },
            {
                image: tanmaya_2,
                sub_image: your_story_logo,
                date: '5 Aug, 2020',
                title: 'YourStory',
                text: '[Funding alert] SaaS startup inFeedo raises $700k in pre-Series A round from Y Combinator and others',
                link: 'https://yourstory.com/2020/07/funding-saas-startup-infeedo-ycombinator',
                featured: false
            },
            {
                image: inc42_img,
                sub_image: bw,
                date: '5 Aug, 2020',
                title: 'Business World ',
                text: 'Y-Combinator Backs inFeedo To Engage Remote Workforces During COVID-19',
                link: 'http://bwdisrupt.businessworld.in/article/Y-Combinator-Backs-inFeedo-To-Engage-Remote-Workforces-During-COVID-19-/05-08-2020-305204/',
                featured: false
            },
            {
                image: tanmaya_2,
                sub_image: vccircle_logo,
                date: '5 Aug, 2020',
                title: 'VC Circle',
                text: 'SaaS startup inFeedo snags funding from Y Combinator, angel investors',
                link: 'https://www.vccircle.com/saas-startup-infeedo-snags-funding-from-y-combinator-angel-investors',
                featured: false
            },
            {
                image: inc42_img,
                sub_image: inc42_logo,
                date: '5 Aug, 2020',
                title: 'inc42',
                text: 'How Y Combinator-Backed inFeedo Is Fixing Employee Engagement For Large Remote Workforces',
                link: 'https://inc42.com/startups/how-y-combinator-backed-infeedo-is-solving-employee-engagement-for-large-remote-workforces/',
                featured: false
            },
            {
                image: kr_asia_img,
                sub_image: kr_asia_logo,
                date: '5 Aug, 2020',
                title: 'Kr Asia',
                text: 'Y Combinator backs Indian SaaS startup inFeedo to expand in SEA',
                link: 'https://kr-asia.com/y-combinator-backs-indian-saas-startup-infeedo-to-expand-in-sea',
                featured: false
            },
            {
                image: inshorts_img,
                sub_image: inshorts_logo,
                date: '5 Aug, 2020',
                title: 'inshorts',
                text: 'SaaS startup inFeedo raises ₹5.2 cr from Y Combinator, others',
                link: 'http://inshorts.com/en/news/saas-startup-infeedo-raises-%E2%82%B952-cr-from-y-combinator-others-1596543684783',
                featured: false
            },
            {
                image: tanmaya_2,
                sub_image: business_line_logo,
                date: '5 Aug, 2020',
                title: 'Business Line',
                text: 'inFeedo raises $700K from Y-Combinator and others',
                link: 'https://www.thehindubusinessline.com/info-tech/infeedo-raises-700k-from-y-combinator-and-others/article32269243.ece',
                featured: false
            },
            {
                image: et_tech_img,
                sub_image: et_tech_logo,
                date: '5 Aug, 2020',
                title: 'ET tech',
                text: 'inFeedo raises $700,000 from Y Combinator & others',
                link: 'https://tech.economictimes.indiatimes.com/news/startups/infeedo-raises-700000-from-y-combinator-others/77341337',
                featured: false
            },
            {
                image: techstory_img,
                sub_image: techstory_logo,
                date: '5 Aug, 2020',
                title: 'TechStory',
                text: 'inFeedo secured $700k in Series A funding round',
                link: 'https://techstory.in/infeedo-secured-700k-in-series-a-funding-round/',
                featured: false
            },
            {
                image: linkedin_img,
                sub_image: linkedin_logo,
                date: '5 Aug, 2020',
                title: 'LinkedIn',
                text: 'How To Build HR Efficiency Within Your Organization For As Little As $50,000 Annually',
                link: 'https://www.linkedin.com/pulse/how-build-hr-efficiency-within-your-organization-little-praveen-dalal/',
                featured: false
            },
            {
                image: people_matters_img,
                sub_image: people_matters_logo,
                date: '5 Aug, 2020',
                title: 'PeopleMatters',
                text: 'inFeedo raises $700,000 funding',
                link: 'https://www.peoplematters.in/article/hr-technology/infeedo-raises-700000-funding-26548',
                featured: false
            },
            {
                image: vjamritra,
                sub_image: vjamritra_logo,
                date: '10 July, 2020',
                title: 'Fresh Juice',
                text: 'Are you happy at your workplace? Amber can tell.',
                link: 'https://www.vjamritraj.com/post/are-you-happy-at-your-workplace-amber-can-tell',
                featured: false
            },
            {
                image: ai_mc,
                sub_image: mc_logo,
                date: '26 Mar, 2020',
                title: 'Moneycontrol',
                text: 'AI has helped double the average employee tenure in banking sector: Survey',
                link: 'https://www.moneycontrol.com/news/business/ai-has-helped-double-the-average-employee-tenure-in-banking-sector-survey-5021791.html',
                featured: false
            },
            {
                image: etr,
                sub_image: et_logo,
                date: '12 Mar, 2020',
                title: 'The Economic Times',
                text: 'AI has helped double the average employee tenure: Report',
                link: 'https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/ai-has-helped-double-the-average-employee-tenure-report/articleshow/74456802.cms',
                featured: true
            },
            {
                image: tanmaya_2,
                sub_image: timesjobs,
                date: '28 Feb, 2020',
                title: 'TimesJobs',
                text: 'My First Job: Tanmaya Jain, Founder & Global CEO, inFeedo',
                link: 'https://content.timesjobs.com/my-first-job-tanmaya-jain-founder-global-ceo-infeedo/articleshow/74286481.cms',
                featured: false
            },
            {
                image: ai_bi,
                sub_image: ai_bi,
                date: '28 Feb , 2020',
                title: 'Business Insider',
                text: 'AI has helped to double average employee tenure in banking sector: Survey',
                link: 'https://www.businessinsider.in/business/news/ai-has-helped-to-double-average-employee-tenure-in-banking-sector-survey/articleshow/74573703.cms',
                featured: false
            },
            {
                image: ai_bw,
                sub_image:bw,
                date: '28 Feb, 2020',
                title: 'Business World',
                text: 'AI Technology Eases Day-to-day Business: Vikram Jit Singh, Fung Group',
                link: 'http://www.businessworld.in/article/AI-Technology-Eases-Day-to-day-Business-Vikram-Jit-Singh-Fung-Group/06-03-2020-185692/',
                featured: false
            },
            {
                image: tanmaya,
                sub_image: thriveglobal,
                date: '28 Feb, 2020',
                title: 'Thriveglobal',
                text: 'Surround yourself with the right people.” With Mitch Russo & Tanmaya Jain',
                link: 'https://thriveglobal.com/stories/surround-yourself-with-the-right-people-with-mitch-russo-tanmaya-jain/',
                featured: false
            },
            {
                image: times_tanmaya,
                sub_image: timesjobs,
                date: '19 Feb, 2020',
                title: 'TimesJobs',
                text: 'Get conscious about unconscious bias! Why an inclusive workplace is now a business imperative',
                link: 'https://content.timesjobs.com/get-conscious-about-unconscious-bias-why-an-inclusive-workplace-is-now-a-business-imperative/articleshow/74206492.cms',
                featured: false
            },
            {
                image: ai_bs,
                sub_image:ai_bs,
                date: '19 Feb, 2020',
                title: 'Business Standard',
                text: 'AI has helped to double average employee tenure in banking sector: Survey',
                link: 'https://www.business-standard.com/article/pti-stories/ai-has-helped-to-double-average-employee-tenure-in-banking-sector-survey-120031100530_1.html',
                featured: false
            },
            {
                image: sun_life,
                sub_image: peoplematters_logo,
                date: '10 Jan, 2020',
                title: 'People Matters',
                text: 'Sun Life Asia Service Centre’s HR Head on digitizing employee experience',
                link: 'https://www.peoplematters.in/article/outlook2020/sun-life-asia-service-centres-hr-head-on-digitizing-employee-experience-24277',
                featured: false
            },
            {
                image: mindful,
                sub_image: bw,
                date: '8 Feb, 2020',
                title: 'Business World',
                text: 'Mindfulness At Work: Slow Down To Improve Productivity & Mental Health',
                link: 'http://www.businessworld.in/article/Mindfulness-At-Work-Slow-Down-to-Improve-Productivity-Mental-Health/08-02-2020-183483/',
                featured: false
            },
            {
                image: fox34,
                sub_image: fox_icon,
                date: '2 Feb, 2020',
                title: 'Fox 34',
                text: 'Global analytics leader Evalueserve onboards Amber, an AI engagement bot by HR-tech startup inFeedo',
                link: 'https://www.fox34.com/story/41641867/global-analytics-leader-evalueserve-onboards-amber-an-ai-engagement-bot-by-hr-tech-startup-infeedo',
                featured: false
            },
            {
                image: employee_bw,
                sub_image: bw,
                date: '7 Jan, 2020',
                title: 'Business World',
                text: 'Why Employee’s Happiness At Work Matters',
                link: 'http://www.businessworld.in/article/Why-Employee-s-Happiness-At-Work-Matters-/28-12-2019-181137/',
                featured: false
            },
            {
                image: robo,
                sub_image: peoplematters_logo,
                date: '3 Jan, 2020',
                title: 'People Matters',
                text: 'inFeedo’s quest to help SEA firms crack the code of engagement',
                link: 'https://www.peoplemattersglobal.com/article/techhrsg/infeedos-quest-to-help-sea-firms-crack-the-code-of-engagement-24217',
                featured: true
            },
            {
                image: entrepreneur_article,
                sub_image: ent,
                date: '2 Jan, 2020',
                title: 'Entrepreneur',
                text: 'Is Your Workplace Culture Employee-First And Adaptive?',
                link: 'https://www.entrepreneur.com/article/344450',
                featured: false
            },
            {
                image: tanmaya_jain,
                sub_image: authority_magazine,
                date: '3 Dec, 2019',
                title: 'Authority Magazine',
                text: 'Here Are 5 Things You Should Know to Create a Successful App or SAAS',
                link: 'https://medium.com/authority-magazine/infeedo-founder-tanmaya-jain-here-are-5-things-you-should-know-to-create-a-successful-app-or-df6a6117d277',
                featured: true
            },
            {
                image: syukri_sudari,
                sub_image: human_resources,
                date: '19 Nov, 2019',
                title: 'Human Resources Online',
                text: 'How AXA Affin GI’s compressed workweek drives high employee engagement',
                link: 'https://www.humanresourcesonline.net/case-study-how-axa-affin-gis-compressed-workweek-drives-high-employee-engagement/',
                featured: false
            },
            {
                image: oyo_logo,
                sub_image: pr_news_logo,
                date: '15 Oct, 2019',
                title: 'PR Newswire',
                text: 'OYO’s HR Team Saves 75% At-Risk Employees Using InFeedo’s Engagement Bot, Amber',
                link: 'https://www.prnewswire.com/in/news-releases/oyo-s-hr-team-saved-75-at-risk-employees-using-infeedo-s-engagement-bot-amber-801285192.html',
                featured: true

            },
            {
                image: sharaf_dg,
                sub_image: zawya_logo,
                date: '16 Sept, 2019',
                title: 'Zawya',
                text: 'Sharaf DG adopts a futuristic strategy to retain 88% at-risk employees with inFeedo\'s AI-enabled engagement bot: Amber',
                link: 'https://www.zawya.com/mena/en/press-releases/story/Sharaf_DG_adopts_a_futuristic_strategy_to_retain_88_atrisk_employees_with_inFeedos_AIenabled_engagement_bot_Amber-ZAWYA20190916075040/',
                featured: false

            },
            {
                image: infeedo_team,
                sub_image: et_logo,
                date: '28 Aug, 2019',
                title: 'Economic Times',
                text: 'This chatbot is helping HR identify unhappy employees before they quit',
                link: 'https://economictimes.indiatimes.com/small-biz/startups/features/this-chatbot-is-helping-hr-identify-unhappy-employees-before-they-quit-infeedo-amber/articleshow/70852705.cms',
                featured: true

            },
            {
                image: infeedo_team2,
                sub_image: et_logo,
                date: '21 Aug, 2019',
                title: 'ET CIO',
                text: 'How Amber, inFeedo’s AI, is shaping the future of employee engagement and retaining talent',
                link: 'https://cio.economictimes.indiatimes.com/news/strategy-and-management/how-amber-infeedos-ai-is-shaping-the-future-of-employee-engagement-and-retaining-talent/70763658',
                featured: true

            },
            {
                image: predictive,
                sub_image: peoplematters_logo,
                date: '16 Aug, 2019',
                title: 'People Matters',
                text: 'Reimagine engagement with predictive analytics and personalization',
                link: 'https://www.peoplematters.in/article/techhrin/reimagine-engagement-with-predictive-analytics-and-personalization-22695',
                featured: false

            },
            {
                image: ai_hr,
                sub_image: ent,
                date: '17 July, 2019 ',
                title: 'Entrepreneur',
                text: 'AI in HR: When Employee Experience Takes Center Stage',
                link: 'https://www.entrepreneur.com/article/336939',
                featured: false

            },
            {
                image: booth,
                sub_image: peoplematters_logo,
                date: '27 Jun, 2019',
                title: 'People Matters',
                text: 'From penniless at TechHR to $1.3 Mn ARR, inFeedo’s foray into the HR tech market',
                link: 'https://www.peoplematters.in/article/techhrin/from-penniless-at-techhr-to-13-mn-arr-infeedos-foray-into-the-hr-tech-market-22149',
                featured: true

            },
            {
                image: tanmaya,
                sub_image: kalzoom,
                date: '13 March, 2019',
                title: 'Kalzoom Advisors',
                text: 'Seizing the chatbot opportunity in HR practices and employee engagement – in conversation with Mr. Tanmaya Jain, Founder, inFeedo',
                link: 'http://www.kalzoomadvisors.com/seizing-the-chatbot-opportunity-in-hr-practices-and-employee-engagement-in-conversation-with-mr-tanmaya-jain-founder-infeedo/',
                featured: false

            },
            {
                image: amber_hi,
                sub_image: mint_logo,
                date: '26 Sept, 2018',
                title: 'Livemint',
                text: 'Heave a sigh of relief HR, Amber’s here to help',
                link: 'https://www.livemint.com/Leisure/G5Dnhxq3i9jt78gDoCgn7K/Heave-a-sigh-of-relief-HR-Ambers-here-to-help.html',
                featured: false

            },
            {
                image: peoplematters,
                sub_image: peoplematters_logo,
                date: '11 Jul, 2018',
                title: 'People Matters',
                text: 'How inFeedo is utilizing AI to interpret employees’ emotions',
                link: 'https://www.peoplematters.in/article/techhr-2018/how-infeedo-is-utilizing-ai-to-interpret-employees-emotions-18727?utm_source=peoplematters&utm_medium=interstitial&utm_campaign=learnings-of-the-day',
                featured: true

            },
            {
                image: chatbot,
                sub_image: et_logo,
                date: '27 Dec, 2017',
                title: 'Economic Times',
                text: 'A new breed of bots is helping thousands of office workers speak up without fear',
                link: 'https://economictimes.indiatimes.com/jobs/why-2018-could-change-your-office-life-forever/articleshow/62148886.cms',
                featured: false

            },
            {
                image: aim_infeedo,
                sub_image: aim,
                date: '11 Sept, 2017',
                title: 'Analytics India Mag',
                text: 'inFeedo and its chatbot “Amber” are disrupting HR industry using AI and Predictive Analytics',
                link: 'https://analyticsindiamag.com/infeedo-chatbot-amber-disrupting-hr-industry-using-ai-predictive-analytics/',
                featured: false

            },
            {
                image: amber_know,
                sub_image: techseen,
                date: '16 May, 2017',
                title: 'Techseen',
                text: 'Now an AI chatbot will resolve Knowlarity’s HR hitches',
                link: 'https://techseen.com/2017/05/16/ai-chatbot-amber-knowlarity-hr/',
                featured: false

            },
            {
                image: infeedo_team4,
                sub_image: inc42,
                date: '12 Dec, 2016',
                title: 'Inc42',
                text: 'HR Analytics Startup inFeedo Raises $200K Angel Funding From Dheeraj Jain, Anupam Mittal, Others',
                link: 'https://inc42.com/flash-feed/infeedo-200k-funding/',
                featured: true

            },
            {
                image: infeedo_team3,
                sub_image: yourstory,
                date: '11 Jul, 2016',
                title: 'Yourstory',
                text: '21-year-old Tanmaya Jain sets out to challenge Microsoft’s Yammer with his employee feedback platform ‘inFeedo’',
                link: 'https://yourstory.com/2016/07/infeedo',
                featured: false

            }
            
        ],
        mentions: [
            {
                image: your_story_23dec,
                source: 'linkedin',
                avatar: user,
                author: "Stellaris Venture Partners",
                text: "Delighted to present the final cohort of #AI4Biz: covering 10 of the most promising AI-SaaS startups in India",
                link: 'https://www.linkedin.com/posts/stellaris-venture-partners_ai4biz-ai-startups-activity-6744285670442848256-tTw2',
                platform: linkedin
            },
            {
                image: tanmaya_2,
                source: 'linkedin',
                avatar: maninder_gulati,
                author: "Maninder Gulati",
                text: "Tanmaya Jain and the team at inFeedo helping companies learn, engage and care about their employees. Early adopter, believer and investor of the product Amber inFeedo",
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6733081990720978944/',
                platform: linkedin
            },
            {
                image: tanmaya_2,
                source: 'twitter',
                avatar: user,
                author: "Inc42",
                text: "@inFeedo is an #AI-enabled engagement bot who periodically chats with employees and helps #HR proactively find those who are disengaged or about to leave. The company helps employees in distress.",
                link: 'https://twitter.com/Inc42/status/1327210884326977537',
                platform: twitter
            },
            {
                image: inshorts_img,
                source: 'twitter',
                avatar: ritesh_malik,
                author: "Dr Ritesh Malik",
                text: "Delighted to see this. @inFeedo is killing it! Glad to be an early spectator & angel investor of @tanmaya17's journey! gratitude, &#11088",
                link: 'https://twitter.com/drriteshmalik/status/1327312525994561536',
                platform: twitter
            },
            {
                image: inshorts_img,
                source: 'twitter',
                avatar: user,
                author: "Y Combinator",
                text: "Congratulations to the @inFeedo(YC S20) team! Y Combinator-backed inFeedo bags $3.2m in round led by ex-Google, Facebook exec",
                link: 'https://twitter.com/ycombinator/status/1327311712354091008',
                platform: twitter
            },
            {
                image: tanmaya_2,
                source: 'twitter',
                avatar: user,
                author: "YourStory",
                text: "Y Combinator-backed SaaS startup #inFeedo raises $3.2M to unlock its next phase of growth.",
                link: 'https://twitter.com/YourStoryCo/status/1327150948721029121',
                platform: twitter
            },
            {
                image: start_lab_img,
                source: 'linkedin',
                avatar: user,
                author: "The StartupLab",
                text: "Founded by Tanmaya Jain in 2013, inFeedo has helped more than 300,000 employees in companies like GE, MakeMyTrip, Dunkin & Dominos feel valued by launching A.I. in HR.",
                link: 'https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6732941171477291008/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: tanmaya_2,
                source: 'linkedin',
                avatar: user,
                author: "YourStory Media",
                text: "Y Combinator-backed SaaS startup #inFeedo raises $3.2M to unlock its next phase of growth.",
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6732916844476284928/',
                platform: linkedin
            },
            {
                image: tanmaya_2,
                source: 'linkedin',
                avatar: kanishka_mallick,
                author: "Kanishka Mallick",
                text: "The preliminary and most critical step is to have a Sponsor championing the Employee Experience (EEx) journey. It’s important to remember that EEx is not just an HR mandate. ",
                link: 'https://www.linkedin.com/posts/kanishkamallick_employee-experience-feelings-experiences-activity-6727061426881486848-lOU8/',
                platform: linkedin
            },
            {
                image: people_matter_funding,
                source: 'linkedin',
                avatar: user,
                author: "People Matters Asia",
                text: "How did the HR tech and worktech space fare in terms of fundraising and investments in the second quarter of this very difficult year? Take a look.",
                link: 'https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6717356940780953600/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: pm_article,
                source: 'linkedin',
                avatar: user,
                author: "Asma Bari",
                text: " &#10084;&#65039; Amber<br> Big shoutout to Varun Puri Tanmaya Jain Nishank Patel Ajay Ruhela Saloni Goyal Yatin Darmwal Ishan Khatterpal Shikhar Kesarwani",
                link: 'https://www.linkedin.com/posts/activity-6700286322344558592-RNEw',
                platform: linkedin
            },
            {
                image: cnbc_img,
                source: 'twitter',
                avatar: user,
                author: "CNBC-TV18",
                text: "India's Saas Startup inFeedo Raises $700k in Pre-Series A Round from Y Combinator And OthersinFeedo plans to use the freshly raised funds to double down on hiring and fill up key management roles including that of a COO, CMO and CTO.",
                link: 'https://twitter.com/CNBCTV18News/status/1290644321419628544',
                platform: twitter
            },
            {
                image: volanews_sm,
                source: 'linkedin',
                avatar: user,
                author: "VOLANEWS",
                text: "SaaS startup @inFeedo raises $700,000 in pre-Series A round of funding from @ycombinator & a clutch of other Indian founders & CEOs. @MeghaVishwanath talks to @tanmaya17 of inFeedo & investor @drriteshmalik about the future of the deal & more",
                link: 'https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6696689243160494080/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: kaizen_young_sm,
                source: 'linkedin',
                avatar: user,
                author: "Kaizen Young Consultants",
                text: "inFeedo recently announced that it had raised its pre-Series A round of funding. The round was closed at $7,00,000 and was led by Y Combinator. A couple of Indian founders and CEOs also took part in the round. ",
                link: 'https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6696675792526680064/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: antrapreneur_business_sm,
                source: 'linkedin',
                avatar: user,
                author: "Antrapreneur The Business Incubator",
                text: "inFeedo Inc., a people analytics-focussed software-as-a-service company, has raised $700,000 (Rs 5.25 crore at current exchange rates) in funding from a clutch of investors including global startup accelerator Y Combinator.Other investors that took part in this pre-Series A round include Innov8 founder Ritesh Malik, Lenskart chief executive officer Peyush Bansal, former Cloudcherry CEO Vinod Muthukrishnan, former Snapdeal chief product officer Anand Chandrasekaran, former Kwench CEO Sunder Nookala and Inventus Law CEO Anil Advani. The 100x Entrepreneur Fund, an early-stage venture capital firm, also took part in this funding exercise via investment platform LetsVenture.",
                link: 'https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6696623209841881088/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: hey_pitch_sm,
                source: 'linkedin',
                avatar: user,
                author: "Hey Pitch",
                text: "SaaS-based analytics startup inFeedo recently announced that it had raised its pre-Series A round of funding. The round was closed at $7,00,000, and was led by Y Combinator. A couple of Indian founders and CEOs also took part in the round. inFeedo has been selected for Y Combinator's Summer 2020 batch, which is taking place remotely due to the COVID-19 pandemic.",
                link: 'https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6696481976947408896/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: business_ex_sm,
                source: 'linkedin',
                avatar: user,
                author: "Business Exchange Network",
                text: 'inFeedo has lately got investment from Y Combinator and other angel investors #investment #angelinvestors #capitalinfusion #fundinground',
                link: 'https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6696426981699002368/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: startup_wonders_sm,
                source: 'linkedin',
                avatar: user,
                author: "Startup Wonders",
                text: 'Analytics-focussed SaaS startup inFeedo has raised ₹5.25 crores in pre-series A funding from innov8 founder Dr Ritesh Malik, former Cisco Webex Experience Management (formerly CloudCherry) CEO Vinod Muthukrishnan, former KWENCH CEO Sunder Nookala, investUS llc Law CEO Anil Advani, former Snapdeal CPO Anand C., and Lenskart.com CEO Peyush Bansal.',
                link: 'https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6696409920138563585/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: startup_news_sm,
                source: 'linkedin',
                avatar: user,
                author: "Startup News",
                text: 'inFeedo raises $700k from Y Combinator SaaS based people analytics inFeedo raised $700k Y Combinator along with Dr Dr Ritesh Malik, founder at OYO-acquired innov8, Peyush Bansal...',
                link: ' https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6696358981847408641/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: tanmaya_2,
                source: 'linkedin',
                avatar: user,
                author: "The StartupLab",
                text: 'Founded by Tanmaya Jain in 2013, inFeedo is a Gurugram headquartered SaaS-based analytics startup. The startup is working with a mission to make the voice of every employee feel heard and valued.',
                link: 'https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6696348588173275136/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: inshorts_img,
                source: 'linkedin',
                avatar: user,
                author: "StartupWorld",
                text: 'inFeedo plans to use $700K funds to increase its workforce twice in number and fill up its C-suite. #investment #startup #saas StartupWorld',
                link: 'https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6696323420898119680/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: tanmaya_2,
                source: 'linkedin',
                avatar: user,
                author: "Daily Startup News",
                text: ' #SaaS-based analytics startup inFeedo recently announced that it had raised its #seriesa round of #funding at $7,00,000, and was led by #YCombinator. ',
                link: 'https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6696272844445286400/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: et_tech_img,
                source: 'linkedin',
                avatar: user,
                author: "Y Combinator",
                text: 'Congrats to the inFeedo (YC S20) team!: inFeedo raises $700k from YC & others -- their AI-powered chatbot helps chief executives and HR leaders measure employee engagement and analyze employee and organizational sentiment.',
                link: 'https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6696580547667386368/?actorCompanyId=6458664',
                platform: linkedin
            },
            {
                image: syukri_sm,
                source: 'linkedin',
                avatar: peter,
                author: "Peter Law",
                text: ' How we act today defines us for years to come. COVID-19 or not, HR must lead the way for organizations to bridge the employee mental wellness gap with AI and analytics.',
                link: 'https://www.linkedin.com/posts/peter-law-79853742_join-the-hr-evolution-2020-4-people-leaders-activity-6692299083022925824-_H60',
                platform: linkedin
            },
            {
                image: taiwo_sm,
                source: 'linkedin',
                avatar: taiwo_img,
                author: "Taiwo 'Dayo-Abatan",
                text: 'It was an early Thursday morning learning session for me and it was most rewarding interacting with HR professionals from Indonesia, Singapore, Malaysia, United Kingdom and Nigeria courtesy of inFeedo',
                link: 'https://www.linkedin.com/posts/taiwoabatan_covid19-wfh-distruption-activity-6676767599138807808-gPiy',
                platform: linkedin
            },
            {
                image: safinas_sm,
                source: 'linkedin',
                avatar: safinas,
                author: 'Safinas Mohd Ibrahim',
                text: 'Sentiment analysis and employee engagement: How AXA AFFIN Life is leveraging AI. Today, we are introducing our new team member, Amber an AI chatbot. Amber is our virtual business partner who is an expert ...',
                link: 'https://www.linkedin.com/posts/safinas-mohd-ibrahim-56295212b_humanexperience-humanresouces-activity-6695960240099352576-zImL',
                platform: linkedin
            },
            {
                image: tarkesh_gupta_sm,
                source: 'linkedin',
                avatar: tarkesh_gupta,
                author: 'Tarkesh Gupta',
                text: 'Proud to share that we are, indeed, a HappyPlaceToWork. 94% of our employees indicated that they are Happy or VeryHappy through our Artificial Intelligence powered virtual Employee Engagement Initiative.',
                link: 'https://www.linkedin.com/posts/tarkeshgupta_proud-happyplacetowork-happy-activity-6687584645594329088-IzHa',
                platform: linkedin
            },
            {
                image: syukri_sm,
                source: 'linkedin',
                avatar: user,
                author: "Syukri Sudari",
                text: ' If there’s anything the last few months has taught us, it’s to learn, unlearn, relearn and adapt quickly in the face of uncertainty. Join me on 29th July as I share key takeaways on being an early #HRtechadopter and why we need to go more digital to be more human with my co-speakers Rudy Manik Tanmaya Jain and moderator Peter Law.',
                link: 'https://www.linkedin.com/posts/syukri-sudari_join-the-hr-evolution-2020-4-people-leaders-activity-6693078555728977920-bWCd',
                platform: linkedin
            },
            {
                image: max,
                source: 'linkedin',
                avatar: user,
                author: 'Anu G',
                text: 'Max Launches \'Amber\' for office and select stores in UAE! We have launched an AI enabled chat bot called Amber. This is enabling us to track employee ...',
                link: 'https://www.linkedin.com/posts/anurattig_amber-engagement-hr-activity-6630826625556668416-EqYY/',
                platform: linkedin
            },
            {
                image: everest_image,
                source: 'linkedin',
                avatar: everest_thumb,
                author: 'Everest Group',
                text: 'inFeedo & Peakon – #EmployeeExperience startups trailblazing employee engagement analytics space.',
                link: 'https://www.linkedin.com/posts/everest-group_employeeexperience-activity-6627493501682704384-kEOV/',
                platform: false
            },
            {   
                source: 'twitter',
                avatar: manuel_vidal,
                author: 'Manuel Vidal',
                text: `Thanks Infeedo!! @Altimetrik is proud to be recognized among the first companies in the world  to successfully use an engagement bot to connect with employees distributed across locations and understand their sentiment in real-time`,
                link: 'https://twitter.com/manuelvidal/status/1213108079711133699',
                platform: twitter
            },
            {
                image: david_post,
                source: 'twitter',
                avatar: david,
                author: 'David Green',
                text: `Congrats @inFeedo - inFeedo’s quest to help SEA firms crack the code of engagement http://ow.ly/Sa5m50xN0OM  via @PeopleMatters2 
                #HRTech #HR #EmployeeExperience #PeopleAnalytics #TechHRSG`,
                link: 'https://twitter.com/david_green_uk/status/1215524952880881665',
                platform: twitter
            },
            {   
                image: image_axa,
                source: 'linkedin',
                avatar: syukri_sudari,
                author: 'Syukri Sudari',
                text: `I was ushering 2019 and welcoming 2020 with a very exciting event last night i.e. the First HR-Hackaton in Malaysia organized by Telekom Malaysia.`,
                link: 'https://www.linkedin.com/posts/syukri-sudari_hrhackathon-hr-hackathon-activity-6618027031730978817-KJvW',
                platform: linkedin
            },
            {
                image: image_rohit,
                source: 'linkedin',
                avatar: rohit_suri,
                author: 'Rohit Suri',
                text: 'GroupM India is proud to be recognized amongst the first few companies in the country to successfully use an engagement bot to connect with all employees across various locations and understand their feedback and sentiments in real time.',
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6605018264257036288/',
                platform: linkedin
            },
            {
                image: image_sonia,
                source: 'linkedin',
                avatar: mediacom,
                author: 'Sonia Fernandes',
                text: 'Amber, our A.I. based talent management system, identified our business leaders who excel in talent engagement. MediaCom Vietnam gets the gold medal with 91% employees scoring themselves as highly engaged...',
                link: 'https://www.linkedin.com/feed/update/activity:6528552160412696576/',
                platform: linkedin
            },
            {
                image: image_altimetrik,
                source: 'linkedin',
                avatar: altimetrik,
                author: 'Geetha Thiagarajan',
                text: '#Amber will be constantly working towards engaging our people. We look forward to the insights generated by Amber to build a more proactive and positive culture at Altimetrik.',
                link: 'https://www.linkedin.com/feed/hashtag/?highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6411901968436031488&keywords=%23Amber&originTrackingId=tdHopeD0T%2BmzZI2ArQrArw%3D%3D',
                platform: linkedin
            },
            {
                image: image_cavin,
                source: 'linkedin',
                avatar: cavinkare,
                author: 'CavinKare',
                text: 'Join us in welcoming Amber! Meet our newly joined member, a virtual assistant, which will help build and maintain employee satisfaction and conduct personalised interactions. ',
                link: 'https://www.linkedin.com/feed/update/activity:6508939185745850368/',
                platform: linkedin
            },
            {
                image: image_emaar,
                source: 'linkedin',
                avatar: emaar,
                author: 'Emaar Hospitality Group',
                text: 'Emaar Hospitality Group Human Resources launches two Chatbots to enhance the associate experience and drive efficiency. Amber by Infeedo – a celebrity Chatbot – will focus on associates’ sentiment analysis in their first-year journey with the Group...',
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6417316380562989056/',
                platform: linkedin
            },
            {
                image: image_emaar_aseem,
                source: 'linkedin',
                avatar: aseem,
                author: 'Aseem Kapoor',
                text: 'At Emaar, we continue our Digital Transformation journey in HR by welcoming our first Artificially Intelligent team member - Amber by Infeedo - a celebrity bot who will reach out to our People and gain their feedback on a regular basis.',
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6520895489779556352/?commentUrn=urn%3Ali%3Acomment%3A(activity%3A6518881463935569921%2C6520895377732931584)',
                platform: linkedin
            },
            {
                image: image_marico,
                source: 'linkedin',
                avatar: ashutosh,
                author: 'Ashutosh Telang',
                text: 'Team Marico welcomes its first digital recruit – “Amber”, designated as “Digital Assistant” to MD & CEO, Saugata Gupta. Amber Bot will interact with members, understand their feelings & concerns and help them in their journey at Marico.',
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6315838803806392320/',
                platform: linkedin
            },
            {
                image: image_crompton,
                source: 'linkedin',
                avatar: satyajit,
                author: 'Satyajit Mohanty',
                text: 'Traditional annual employee engagement surveys are too time consuming, has time lag and most critically, in my view, does not develop line manager ownership. We have implemented real time AI based tool Amber...',
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6498018138980999168/',
                platform: linkedin
            },
            {
                image: image_ifcci,
                source: 'twitter',
                avatar: indo_french,
                author: 'Indo-French Chamber',
                text: `#IFCCI HR Committee organized a #webinar on “#AI in #HR: When Employee Experience Takes Centre Stage” today. All HR representatives from leading French & Indian Companies learnt how @inFeedo's #chatbot “#Amber” is transforming HR industry using AI and predictive analytics.`,
                link: 'https://twitter.com/IFCCI1/status/1163822827406909441',
                platform: twitter
            },
            {
                image: image_bharti,
                source: 'linkedin',
                avatar: mmh_beg,
                author: 'MMH Beg',
                text: 'We have recently inducted a new one-of-a-kind employee named Amber, who is not human, into Bharti Foundation. She is empowered by Artificial Intelligence(AI) who will help the leadership team to continuously gauge the engagement level of people and act on the areas of improvement that surface in our culture. ',
                link: 'https://www.linkedin.com/feed/update/activity:6499887120436559872/',
                platform: linkedin
            },
            {
                source: 'twitter',
                avatar: et_rise,
                author: 'ET RISE',
                text: 'Amber gives a heads up to the #HR about employees who may eventually end up resigning and the reason behind it. @inFeedo #ArtificialIntelligence #chatbots',
                link: 'https://twitter.com/ET_RISE/status/1166225076179501056',
                platform: twitter
            },
        ],
        one: [],
        two: [],
        three: []
    }

    componentDidMount() {
        this.createVerticalArrays();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.tab !== this.props.tab) {
            this.createVerticalArrays();
        }
    }

    createVerticalArrays = () => {
        const { articles, mentions } = this.state;
        const one = []; 
        const two = [];
        const three = [];
        const posts = this.props.tab === 'articles' ? articles : mentions;
        posts.forEach((article, index) => {
            if(index % 3 === 0){
                one.push(article);
            }
            if(index % 3 === 1){
                two.push(article);
            }
            if(index % 3 === 2){
                three.push(article);
            }
        });
        this.setState({one, two, three});
    }

    render() {
        const { one, two, three, articles, mentions } = this.state;
        const { tab } = this.props;
        return (
            <div className="pd-15-mobile">
                <div className="tile is-ancestor">
                    <div className="tile is-vertical is-4 flex-wrap is-hidden-mobile">
                        {one.map((article , index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <NewsfeedCard tab={tab} card_data={article}/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="tile is-vertical is-4 flex-wrap is-hidden-mobile">
                        {two.map((article , index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <NewsfeedCard tab={tab} card_data={article}/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="tile is-vertical is-4 flex-wrap is-hidden-mobile">
                        {three.map((article , index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <NewsfeedCard tab={tab} card_data={article}/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="tile is-vertical is-4 flex-wrap is-hidden-tablet">
                        {tab === 'articles'? articles.map((article , index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <NewsfeedCard tab={tab} card_data={article}/>
                                </div>
                            </div>
                        )): mentions.map((article , index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <NewsfeedCard tab={tab} card_data={article}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}
