import React from 'react';
import parse from 'html-react-parser';

function Video({ video, onVideoClick }) {
    return (
        <div onClick={onVideoClick.bind(this, video.id)}>
            <img className="mg-bottom-10 is-fullwidth pointer" src={video.thumbnail} alt="thumbnail"/>
            <p className="columns pd-8 is-vcentered is-mobile is-desktop"> 
                <span className="column font-15 has-text-left is-8 is-8-mobile">{parse(video.text)}</span>
                <span className="column is-4 has-text-right is-4-mobile"><img src={video.logo} alt="logo"/></span>
            </p>
        </div>
    )
}

export default Video
